import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { NbAuthService, NbDummyAuthStrategy } from '@nebular/auth';
import { Router } from '@angular/router';

/**
 * TokenInterceptor
 * @see https://angular.io/guide/http#intercepting-all-requests-or-responses
 * @see https://angular.io/guide/http#intercepting-requests-and-responses
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private authService: NbAuthService;
    // private tokenService: NbAuthJWTToken;

    constructor(private injector: Injector, private router: Router) {
    }

    public getToken(): string {
        const token = localStorage.getItem('auth_app_token');
        if (token == null)
            return '';
        else
            return JSON.parse(token).value;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('intercept');

        this.authService = this.injector.get(NbAuthService); // get it here within intercept

        // Get the auth token from the storage.
        const authToken = this.getToken();

        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        const authReq = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + authToken),
        });

        this.authService.isAuthenticated().subscribe((result) => {
            if (result) {
                // console.log('logged');
            } else {
                this.router.navigateByUrl('/auth/login');
            }
        });

        return next.handle(authReq);
    }

}
