import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, User, UserData } from '../data/users';
import { HttpClient } from '@angular/common/http';

declare var user: User;

@Injectable()
export class UserService extends UserData {

  private time: Date = new Date;

  private users = {
    nick: { name: 'Nick Jones', picture: 'assets/images/nick.png' },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };
  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private contacts: Contacts[] = [
    { user: this.users.nick, type: this.types.mobile },
    { user: this.users.eva, type: this.types.home },
    { user: this.users.jack, type: this.types.mobile },
    { user: this.users.lee, type: this.types.mobile },
    { user: this.users.alan, type: this.types.home },
    { user: this.users.kate, type: this.types.work },
  ];
  private recentUsers: RecentUsers[] = [
    { user: this.users.alan, type: this.types.home, time: this.time.setHours(21, 12) },
    { user: this.users.eva, type: this.types.home, time: this.time.setHours(17, 45) },
    { user: this.users.nick, type: this.types.mobile, time: this.time.setHours(5, 29) },
    { user: this.users.lee, type: this.types.mobile, time: this.time.setHours(11, 24) },
    { user: this.users.jack, type: this.types.mobile, time: this.time.setHours(10, 45) },
    { user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 42) },
    { user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 31) },
    { user: this.users.jack, type: this.types.mobile, time: this.time.setHours(8, 0) },
  ];

  constructor(private http: HttpClient) {
    super();
  }

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }

  getMe(): Promise<User> {
    return this.http.get<any>('/api/v1/user/me')
      .toPromise()
      .then(res => <any[]>res.payload)
      .then(data => {
        const me = {} as User;
        me.name = data['fullName'];
        me.firstName = data['firstName'];
        me.lastName = data['lastName'];
        me.email = data['email'];
        me.mobileNumber = data['mobileNumber'];
        me.picture = 'assets/images/jack.png';
        me.roles = [];
        data['roles'].forEach((element: { name: string; }) => me.roles.push(element.name.toLowerCase()));

        globalThis.user = { ...me };

        return me;
      })
      .catch(() => {
        globalThis.user = {
          name: 'Admin',
          firstName: "Admin",
          lastName: "User",
          email: 'admin@gmail.com',
          mobileNumber: '+48 999 00 00',
          picture: 'assets/images/jack.png',
          roles: 'admin',
        }

        return globalThis.user;
      });
  }

  getCurrentUser(): User {
    return globalThis.user;
  }

  getRoles(): Observable<string[]> {
    return observableOf(globalThis.user?.roles);
  }

  updateProfile(me: User): Promise<any> {
    return this.http.post<any>('/api/v1/user', me)
      .toPromise()
      .then(res => <any[]>res.payload)
      .then(data => data);
  }

}

