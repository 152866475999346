import { Observable } from 'rxjs';

export interface User {
  id?: string;
  name: string;
  picture: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  roles?: string[];
  phone?: string;
}

export interface Contacts {
  user: User;
  type: string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract getContacts(): Observable<Contacts[]>;
  abstract getRecentUsers(): Observable<RecentUsers[]>;
  abstract getMe(): Promise<User>;
  abstract getCurrentUser(): User;
  abstract updateProfile(user: User): Promise<User>;
}
