import { Component, OnInit } from '@angular/core';
import { NbLogoutComponent } from '@nebular/auth';

@Component({
  selector: 'ngx-custom-logout',
  templateUrl: './custom-logout.component.html',
  styleUrls: ['./custom-logout.component.scss'],
})
export class CustomLogoutComponent extends NbLogoutComponent implements OnInit {

  ngOnInit(): void {
    // base class ngOnInit() mutlaka cagir. Yoksa, gerekli
    // init kodlari run olmadigi icin dogru calismaz.
    super.ngOnInit();
    // console.log('custom logout ngOnInit');
  }

  logout(strategy: string): void {
    // console.log('custom logout');
    localStorage.removeItem('auth_app_token');

    // base class'ın methodunu da cagir. O da baska isler yapıyordur.
    // eger call etmezsen override edilen methoddaki isler yapilmamis olur.
    // Her dilde oldugu gibi dikkat ediyoruz!!!
    super.logout(strategy);
  }
}
